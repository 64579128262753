if ('define' in window) {
define("discourse/theme-11/discourse/components/search-banner", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/utilities", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _utilities, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(11);
  const themePrefix = key => `theme_translations.11.${key}`;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <div
      class="{{concat (theme-setting 'plugin_outlet') '-outlet'}} search-banner"
    >
      <div
        class="custom-search-banner"
        {{did-insert this.didInsert}}
        {{will-destroy this.willDestroy}}
      >
        <div class="wrap custom-search-banner-wrap">
          <h1>{{html-safe (theme-i18n "search_banner.headline")}}</h1>
          <PluginOutlet @name="search-banner-below-headline" />
          <p>{{html-safe (theme-i18n "search_banner.subhead")}}</p>
          <div class="search-menu">
            {{#unless this.buttonText}}
              <SearchIcon />
            {{/unless}}
            <SearchMenu />
            {{#if this.buttonText}}
              <SearchIcon
                @buttonText={{this.buttonText}}
                @buttonClass="has-search-button-text"
              />
            {{/if}}
          </div>
          <PluginOutlet @name="search-banner-below-input" />
        </div>
      </div>
    </div>
  {{/if}}
  */
  {
    "id": null,
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[15,0,[29,[[28,[37,2],[[28,[37,3],[11,\"plugin_outlet\"],null],\"-outlet\"],null],\" search-banner\"]]],[12],[1,\"\\n    \"],[11,0],[24,0,\"custom-search-banner\"],[4,[38,4],[[30,0,[\"didInsert\"]]],null],[4,[38,5],[[30,0,[\"willDestroy\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"wrap custom-search-banner-wrap\"],[12],[1,\"\\n        \"],[10,\"h1\"],[12],[1,[28,[35,7],[[28,[37,8],[11,\"search_banner.headline\"],null]],null]],[13],[1,\"\\n        \"],[8,[39,9],null,[[\"@name\"],[\"search-banner-below-headline\"]],null],[1,\"\\n        \"],[10,2],[12],[1,[28,[35,7],[[28,[37,8],[11,\"search_banner.subhead\"],null]],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"search-menu\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"buttonText\"]]],[[[1,\"            \"],[8,[39,12],null,null,null],[1,\"\\n\"]],[]],null],[1,\"          \"],[8,[39,13],null,null,null],[1,\"\\n\"],[41,[30,0,[\"buttonText\"]],[[[1,\"            \"],[8,[39,12],null,[[\"@buttonText\",\"@buttonClass\"],[[30,0,[\"buttonText\"]],\"has-search-button-text\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n        \"],[8,[39,9],null,[[\"@name\"],[\"search-banner-below-input\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"concat\",\"theme-setting\",\"did-insert\",\"will-destroy\",\"h1\",\"html-safe\",\"theme-i18n\",\"plugin-outlet\",\"p\",\"unless\",\"search-icon\",\"search-menu\"]]",
    "moduleName": "discourse/components/search-banner.hbs",
    "isStrictMode": false
  });
  class SearchBanner extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    willDestroy() {
      super.willDestroy(...arguments);
      document.documentElement.classList.remove("display-search-banner");
    }
    static #_4 = (() => dt7948.n(this.prototype, "willDestroy", [_object.action]))();
    get displayForRoute() {
      const showOn = settings.show_on;
      const currentRouteName = this.router.currentRouteName;
      if (showOn === "homepage") {
        return currentRouteName === `discovery.${(0, _utilities.defaultHomepage)()}`;
      } else if (showOn === "top_menu") {
        return this.siteSettings.top_menu.split("|").any(m => `discovery.${m}` === currentRouteName);
      } else if (showOn === "discovery") {
        return currentRouteName.startsWith("discovery.");
      } else {
        // "all"
        return currentRouteName !== "full-page-search" && !currentRouteName.startsWith("admin.");
      }
    }
    get displayForUser() {
      const showFor = settings.show_for;
      return showFor === "everyone" || showFor === "logged_out" && !this.currentUser || showFor === "logged_in" && this.currentUser;
    }
    get buttonText() {
      const buttonText = _discourseI18n.default.t(themePrefix("search_banner.search_button_text"));
      // this is required for when the English (US) locale is empty
      // and the site locale is set to another language
      // otherwise the English (US) fallback key is rendered as the button text
      // https://meta.discourse.org/t/bug-with-search-banner-search-button-text-shown-in-search-banner-theme-component/273628
      if (buttonText.includes("theme_translations")) {
        return false;
      }
      return buttonText;
    }
    get shouldDisplay() {
      return this.displayForRoute && this.displayForUser;
    }
    didInsert() {
      // Setting a class on <html> from a component is not great
      // but we need it for backwards compatibility
      document.documentElement.classList.add("display-search-banner");
    }
    static #_5 = (() => dt7948.n(this.prototype, "didInsert", [_object.action]))();
  }
  _exports.default = SearchBanner;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SearchBanner);
});
}
