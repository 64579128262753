if ('define' in window) {
define("discourse/theme-11/discourse/components/search-icon", ["exports", "@glimmer/component", "discourse/components/d-button", "discourse/helpers/concat-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dButton, _concatClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(11);
  const themePrefix = key => `theme_translations.11.${key}`;
  class SearchIcon extends _component.default {
    get advancedSearchButtonHref() {
      return "/search?expanded=true";
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @icon="magnifying-glass"
          @translatedLabel={{@buttonText}}
          @title="search.open_advanced"
          class={{concatClass "btn search-icon" @buttonClass}}
          @href={{this.advancedSearchButtonHref}}
        />
      
    */
    {
      "id": null,
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"btn search-icon\",[30,1]],null]]],[[\"@icon\",\"@translatedLabel\",\"@title\",\"@href\"],[\"magnifying-glass\",[30,2],\"search.open_advanced\",[30,0,[\"advancedSearchButtonHref\"]]]],null],[1,\"\\n  \"]],[\"@buttonClass\",\"@buttonText\"],false,[]]",
      "moduleName": "/discourse/theme-11/discourse/components/search-icon",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SearchIcon;
});
}
